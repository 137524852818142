/* Import Open Sans */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i,900,900i');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

html {
  background-color: #F6F8F8;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  line-height: 16px;
  color: #444;
}

.leaflet-container {
  height: 100%;
  width: 100%;
}