@import url(https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
* {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
 box-sizing: border-box;
}
a {
	text-decoration: none;
}
a:active,
a:hover {
	outline: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}
ol,
ul {
	list-style: none;
}
blockquote,
q {
	quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
a,
a[href],
input[type='submit'],
input[type='image'],
label[for],
select,
button {
  cursor: pointer;
}
input,
textarea {
	outline: 0;
}
/* Import Open Sans */

html {
  background-color: #F6F8F8;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  line-height: 16px;
  color: #444;
}

.leaflet-container {
  height: 100%;
  width: 100%;
}
